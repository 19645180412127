import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newSwipeOpts',
})
export class NewSwipeOptsPipe implements PipeTransform {
  transform(prevOpts: any, play: boolean = false, looper: boolean = false) {
    const slidesOpts: any = prevOpts;
    slidesOpts.loop = looper;
    if (play) {
      slidesOpts.autoplay = {
        delay: 3000,
        disableOnInteraction: true,
      };
    }
    return slidesOpts;
  }
}