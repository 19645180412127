import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currency',
})
export class CurrencyPipe implements PipeTransform {
  transform(price: number, currency: any) {
    if (!currency && price) {
      try {
        return price ? price.toFixed(2) : price;
      } catch {
        return price;
      }
    } else if (!price || isNaN(price)) {
      return 0;
    }

    if (Number(currency?.type) === 1) {
      price = Number((price * currency.change).toFixed(2));
    } else if (Number(currency?.type) === 0) {
      price = Number((price / currency.change).toFixed(2));
    }
    return price;
  }
}
