import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  public currencySource = new EventEmitter();
  private _domainId = environment.domainId;
  public currency: any = undefined;
  
  constructor(
    private httpSv: HttpService,
  ) {
    this.getCurrencyStorage();
  }

  getCurrencyStorage(){
    this.currency = localStorage.getItem('currency');
    if(this.currency && this.currency !== 'undefined'){
      (this.currency = JSON.parse(this.currency));
      this.currency.change = Number(this.currency.change);
    } else this.currency = undefined;
    this.currencySource.emit(this.currency);
  }

  async getCurrencies(){
    const res: any = await this.httpSv.itemIndex('currency', `${this._domainId}/1`);
    return res.data;
  }

  // async setCurrency(product, currency){
  //   this.currency = currency;
  //   let currencyName;
  //   let price = product.price;
  //   let promotionalPrice = product.promotionalPrice;
  //   let priceWSale = product.priceWSale;
  //   let promotionalPriceWSale = product.promotionalPriceWSale;
  //   const change = this.currency?.change;
  //   if(this.currency?.type === 1){
  //     price = (product.price * change).toFixed(2);
  //     promotionalPrice =  (product.promotionalPrice * change).toFixed(2);
  //     priceWSale = (product.priceWSale * change).toFixed(2);
  //     promotionalPriceWSale = (product.promotionalPriceWSale * change).toFixed(2);
  //   }else if(this.currency?.type === 0){
  //     price = (product.price / change).toFixed(2);
  //     promotionalPrice =  (product.promotionalPrice / change).toFixed(2);
  //     priceWSale = (product.priceWSale / change).toFixed(2);
  //     promotionalPriceWSale = (product.promotionalPriceWSale / change).toFixed(2);
  //   }
    
  //   if(price === '0.00') price = 0;
  //   else if(promotionalPrice === '0.00') promotionalPrice = 0;
  //   else if(priceWSale === '0.00') priceWSale = 0;
  //   else if(promotionalPriceWSale === '0.00') promotionalPriceWSale = 0;
    
  //   currencyName = this.currency?.symbol;
  //   return { 
  //     price,
  //     promotionalPrice,
  //     priceWSale,
  //     promotionalPriceWSale,
  //     currencyName,
  //   }
  // }
}
